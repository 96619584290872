@import "variables";

.btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1px;
}
.course-container-base {
  padding-top: 16px;
  background: $enrolled-bg-color;
  background: $color-white;
}
  
.course-detail-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1px;
}