@import "variables";

.menu-avatar {
    background-color: $menu-item-color;
}
.menu-icon {
    color: $menu-icon-color;
    margin-right: 5px;
}
.menu-item-color {
    color: $menu-item-color;
}