$primary-color: #1976d2;
$app-header-bg: #282c34;
$color-white: #ffffff;
$color-alternative-red: #ef4f69;
$app-link: #282c34;
$icon-color: #1976d2;
$icon-disabled-color: #BDBDBD;
$color-black: black;
$color-green: #2e7d32;
$enrolled-bg-color: #FAF9FB;
$enrolled-hover-color: #E1E0E1;
$menu-icon-color: #808080;
$menu-item-color: #252525;

$learnsoftsite-black: #161723;
$learnsoftsite-light-grey: #f6f8fa;
$learnsoftsite-light-blue: #387ad3;
$learnsoftsite-logo-blue: #142e95;
$learnsoftsite-primary-red: #f2395a;
$learnsoftsite-purple: #afb1e7;
$learnsoftsite-white: white;
$learnsoftsite-grey: #f0efee;
$learnsoftsite-thistle: #f4c8e2;
$learnsoftsite-royal-blue: #085ac9;
$learnsoftsite-dark-blue: #0b2559;
$learnsoftsite-teal: #10acc0;
$learnsoftsite-cta: #ffe164;
$learnsoftsite-hover-state: #fad94d;
$learnsoftsite-dark-red: #ce4257;


$color-overdue: #f8285d;
$color-in-progress: #feda48;
$color-not-started: #1b9db2;