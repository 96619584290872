@import "variables";
.box-flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.box-flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
