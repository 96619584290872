@import "variables";

.product-image{
  border-radius: 6px;
  position: absolute;
  overflow: hidden;
  box-shadow: 5px 5px 8px 1px rgba(0, 0, 0, .15);
  &.certificate{
    z-index: 4;
    position: absolute;
    top: 42%;
    bottom: auto;
    left: 0%;
    right: auto;
  }
  &.calendar{
    width: auto;
    max-height: 250px;
    max-width: 228px;
    position: absolute;
    top: -100px;
    right: 2%;
  }
}

.hero-image{
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-height: 300px;
  object-fit: contain;
  margin-top: auto;
  margin-bottom: auto;
}

.h2-learnsoft{
  color: $learnsoftsite-black;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  position: relative;
  .text-color-red{
    color: $learnsoftsite-primary-red;
  }
}

.p-hello{
  color: #4cadc0;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: 'Rubik', sans-serif;;
  font-size: 35px;
  font-weight: 700;
  line-height: 50px;
  position: relative;
}

.p-welcome{
  color: $learnsoftsite-black;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;;
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  position: relative;
}

.login-wrapper{
  background-color: #f6f8fa;
  border-radius: 5px;
  padding: 40px 40px;
  h2{
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    font-size: 22px;
    margin: 0.3em 0 0.5em 0;
  }
  a{
    color: $learnsoftsite-light-blue;
  }
  .help{
    p{
      font-family: 'Rubik', sans-serif;
      font-weight: 500;
      font-size: 12px;
    }
  }
}

.p-login{
  color: $learnsoftsite-primary-red;
  z-index: 10;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Rubik', sans-serif;;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  position: relative;
}

.p-learnsoft{
  color: $learnsoftsite-black;
  z-index: 10;
  margin-bottom: 32px;
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  position: relative;
}

.btn-yellow{
  z-index: 1;
  background-color: $learnsoftsite-cta;
  color: $learnsoftsite-black;
  text-align: left;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  text-decoration: none;
  transition: all .25s ease-in-out;
  display: inline-block;
  position: relative;
}

.type-accent-square{
  background-color: $learnsoftsite-light-blue;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  top: -32px;
  left: -24px;
  display: block;
  position: absolute;
  bottom: auto;
  right: auto;
}