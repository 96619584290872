@import "variables";

.react-calendar {
  border: 0 !important;
}

.react-calendar__month-view__days__day--weekend {
  color: $color-alternative-red;
}

.css-1teqysn {
  border-bottom: 0 !important;
}

.react-calendar{
  .react-calendar__tile{
    padding: 5px 3px;
  }
  .react-calendar__month-view__days__day{
    abbr{
      border: 2px solid transparent;
      border-radius: 50%;
      box-sizing: border-box;
      display: inline-block;
      line-height: 2;
      width: 30px;
      height: 30px;
    }
    &.date-overdue{
      abbr{
        border: 2px solid $color-overdue;
      }
    }
    &.date-in-progress{
      abbr{
        border: 2px solid $color-in-progress;
      }
    }
    &.date-not-started{
      abbr{
        border: 2px solid $color-not-started;
      }
    }
    &.date-for-any{
      abbr{
        border: 2px solid rgb(206, 206, 206);
      }
    }
  }

}