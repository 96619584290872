.p-treeselect{
  
}

.p-tree-container{
  list-style: none;
  margin: 0 0 0 0;
  padding: 1em 0 0 1em;
}

.p-treenode-children{
  list-style: none;
}

.p-treenode-content{
  display: flex;
  gap: 0.4em;
}

.p-treeselect-header{
  display: none;
}

.p-treeselect-panel{
  background-color: white;
}

.p-treenode{
  margin: 0 0 0.4em 0;
  padding: 0.2em;
}

.p-treenode-selectable{
  cursor: pointer;
}

.p-treenode-leaf{
  cursor: pointer;
  button{
    display: none;
  }
}

.p-treenode[aria-selected="true"] {
  background-color: rgba(0,0,0,0.1);
}