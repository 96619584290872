@import "../../variables.scss";

.object-table {
    background: $color-white;

}
.object-container-base {
    padding-top: 16px;
    background: $color-white;    
  }

.box-flex-row {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }